<template>
  <!-- This component is used with both markdown and non-markdown (slots) content -->
  <li v-if="listItem || $slots.default" class="flex flex-row space-x-3">
    <div class="brand-list-icon-container">
      <span class="brand-list-icon" :class="computedBulletsColourClass"></span>
    </div>
    <div v-if="$slots.default" class="break-word">
      <slot></slot>
    </div>
    <div v-else class="break-word" v-html="$md.render(listItem)" />
  </li>
</template>

<script>
import { inject } from 'vue'
import { stringEmpty } from '~/utils/helpers'

export default {
  name: 'BrandListItem',
  props: {
    listItem: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'blue'
    }
  },
  setup() {
    const listItemBulletsColourClass = inject(
      'listItemBulletsColourClass',
      null
    )
    return { listItemBulletsColourClass }
  },
  computed: {
    computedBulletsColourClass() {
      return !stringEmpty(this.listItemBulletsColourClass)
        ? this.listItemBulletsColourClass
        : `bg-brand-${this.color}-300`
    }
  }
}
</script>
<style scoped lang="postcss">
.brand-list-icon-container {
  @apply flex items-center;
  margin-top: 0.125em;
  height: 1em;
}

.brand-list-icon {
  @apply block w-2 h-2 mt-1;
}
</style>
